var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navbar'),_c('div',{staticClass:"mianContainer teamDetail"},[_c('div',{staticClass:"photoSide",style:({
        backgroundImage: 'url(' + _vm.server_url + '/' + _vm.data.image + ')',
      })}),_c('div',{staticClass:"textSide align-items-center"},[_c('div',{staticClass:"align-items-center"},[_c('PathPage',{staticClass:"flex-direction-column align-items-top"}),_c('h2',{staticClass:"mtavruliBOLD",domProps:{"innerHTML":_vm._s(
            _vm.lang == 'ka'
              ? _vm.removeHTML(_vm.data.fullNameKA)
              : _vm.removeHTML(_vm.data.fullNameEN)
          )}}),_c('h5',{domProps:{"innerHTML":_vm._s(
            _vm.lang == 'ka'
              ? _vm.removeHTML(_vm.data.positionKA)
              : _vm.removeHTML(_vm.data.positionEN)
          )}}),_c('p',{staticClass:"teamMail mb-0",domProps:{"innerHTML":_vm._s(_vm.removeHTML(_vm.data.email))}}),_c('p',{staticClass:"text pt-3",domProps:{"innerHTML":_vm._s(_vm.lang == 'ka' ? _vm.data.descriptionKA : _vm.data.descriptionEN)}})],1)])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }