<template>
  <div>
    <Navbar />

    <div class="mianContainer teamDetail">
      <div
        class="photoSide"
        v-bind:style="{
          backgroundImage: 'url(' + server_url + '/' + data.image + ')',
        }"
      ></div>
      <div class="textSide align-items-center">
        <div class="align-items-center">
          <PathPage class="flex-direction-column align-items-top" />

          <h2
            class="mtavruliBOLD"
            v-html="
              lang == 'ka'
                ? removeHTML(data.fullNameKA)
                : removeHTML(data.fullNameEN)
            "
          ></h2>
          <h5
            v-html="
              lang == 'ka'
                ? removeHTML(data.positionKA)
                : removeHTML(data.positionEN)
            "
          ></h5>

          <p class="teamMail mb-0" v-html="removeHTML(data.email)"></p>

          <p
            v-html="lang == 'ka' ? data.descriptionKA : data.descriptionEN"
            class="text pt-3"
          ></p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import env from "../../env.json";
import axios from "axios";
import Navbar from "./../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "./.././Footer/newFooter/Footer.vue";
import PathPage from "../../views/UserPages/PathPage.vue";
export default {
  components: {
    Navbar,
    Footer,
    PathPage,
  },
  data() {
    return {
      data: {},
      lang: "ka",
      server_url: env.server_url,
    };
  },
  methods: {
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
  },

  mounted() {
    axios
      .get(`${env.host}/get/once/team/${this.$route.params.slug}/null/null`)
      .then((result) => {
        console.log(result);
        this.data = result.data.item;
      })
      .catch((err) => console.log(err));
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
};
</script>

<style scoped>
* {
  color: #666666;
}
.teamMail >>> * {
  font-family: mtavruliBOLD;
  font-size: 14px;
  color: #4d4d4c;
  margin-bottom: 0px;
}
h1 >>> * {
  text-transform: uppercase;
}
.mianContainer {
  width: 100%;
  margin: auto;
  padding: 0px;
  max-width: 1400px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - 180px);
}
.photoSide {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.photoSide img {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.teamDetail h1 {
  font-weight: bold;
  font-family: mtavruli;
}
.teamDetail h2,
h1,
h3,
h4,
h6 h5 {
  color: #734c8a;
  text-transform: uppercase;
}

.text {
  font-size: 16px;
  color: #333333;
  /* font-weight: bold; */
}

.textSide {
  /* border: 2px solid yellow; */
  align-items: center;
  display: flex;
  padding-left: 40px;
}
@media all and (max-width: 1500px) {
  .textSide {
    padding: 20px !important;
  }
}

@media all and (max-width: 992px) {
  .mianContainer {
    grid-template-columns: 1fr;
  }
  .photoSide {
    height: 100vw;
  }

  .textSide {
    /* margin-top: 20px; */
    padding: 0px;
  }
}
</style>
